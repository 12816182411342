<template>
  <div class="table-prime-cell-image">
    <img
      alt="Изображение"
      :src="src"
    >
  </div>
</template>

<script>
import { getHeaders } from '@/utils'
import constants from '@/constants/url'

const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onerror = reject
  reader.onload = () => {
    resolve(reader.result)
  }
  reader.readAsDataURL(blob)
})

export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      src: '',
      downloadURL: 'download_resource',
      baseURL: constants.URL
    }
  },
  created() {
    this.setSource()
  },
  methods: {
    async setSource() {
      const url = `${this.baseURL}/${this.downloadURL}/${this.value}`
      try {
        const res = await fetch(url, { headers: getHeaders() })
        const blob = await res.blob()
        const base64 = await convertBlobToBase64(blob)

        this.src = base64
      } catch (e) {
        console.warn(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-prime-cell-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  img {
    height: 36px;
  }
}
</style>
