<template>
  <div class="table-prime r-table">
    <DataTable
      :ref="tableId"
      :class="['p-datatable-sm p-datatable-gridlines', { clickable }]"
      :value="tableData"
      :scrollable="true"
      row-hover
      scroll-height="flex"
      :resizable-columns="true"
      column-resize-mode="expand"
      :reorderable-columns="true"
      :rows="rows"
      data-key="id"
      :selection.sync="selectedObjects"
      @page="handlePage"
      @column-resize-end="onColumnResizeEnd"
      @column-reorder="onColumnReorder"
      @row-click="clickHandler"
    >
      <Column
        header-style="width: 40px;"
        selection-mode="multiple"
      />
      <Column
        v-for="item in sortedColumns"
        :key="item.header"
        :field="item.title"
        :header-style="getHeaderStyle(item)"
      >
        <template #header>
          <div
            class="table-prime__header-sort"
            @click="customSort(item.title)"
          >
            <r-icon
              v-if="hasSortIcon(item.title)"
              :name="getSortIcon(item.title)"
              :size="24"
              color-type="accent-primary"
            />
            <r-title
              type="subtitle-2"
              :title="getItemTitle(item)"
            >
              {{ getItemTitle(item) }}
            </r-title>
          </div>
        </template>
        <template #body="slotProps">
          <table-prime-cell-image
            v-if="isIconCell(item)"
            :value="getValue(item, slotProps.data)"
          />
          <div
            v-else-if="item.type === 'boolean'"
            class="table-prime__cell center"
          >
            <r-icon
              v-if="getValue(item, slotProps.data)"
              name="check"
              :size="20"
            />
          </div>
          <div
            v-else
            class="table-prime__cell"
          >
            <span class="table-prime__cell-inner">
              {{ getValue(item, slotProps.data) }}
            </span>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

import tablePrimeCellImage from './components/table-prime-cell-image'

import { arrayMoveByIndex } from '@/utils'
import { formatValue } from './helpers'

export default {
  components: {
    DataTable,
    Column,
    tablePrimeCellImage
  },
  props: {
    tableId: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    selectedColumns: {
      type: Array,
      required: true
    },
    isReady: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    multisort: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 0,
      tableData: null,
      activeColumns: null,
      originalRows: {},
      selectedObject: null,
      selectedObjects: null,
      columnsStyle: {},
      singleColumnWidth: 120
    }
  },
  computed: {
    menuModalMap() {
      if (!this.menuModal?.length) return []
      return this.menuModal.map(e => {
        e.object = this.selectedObject

        return e
      })
    },
    iconsSourceId() {
      return this.$store.state.book.iconsSourceId
    },
    activeBookSourceId() {
      return this.$store.state.book?.activeBook?.source_id || null
    },
    activeBookId() {
      return this.$store.state.book.activeBook?.id || {}
    },
    sortedColumns() {
      if (!this.selectedColumns?.length) return []
      return (
        this.selectedColumns.map(e => {
          const index = this.columns.findIndex(v => v.title === e)
          return this.columns[index]
        }) || []
      )
    },
    sortConfig() {
      return (
        this.$store.state.tablePrime.sortConfig[this.tableId] || {
          field: null,
          order: null
        }
      )
    },
    multiSortConfig() {
      return this.$store.state.tablePrime.multiSortConfig[this.tableId] || {}
    },
    isIconsLayer() {
      return this.iconsSourceId === this.activeBookSourceId
    },
    changedRows() {
      return this.$store.state.tablePrime.changedRows[this.tableId] || null
    },
    rows() {
      return this.$store.state.tablePrime.rows[this.tableId] || 50
    }
  },
  watch: {
    data: {
      handler: function(val) {
        this.setSingleColumnWidth()
        this.tableData = cloneDeep(val)
        this.selectedObjects = null
        this.page = 0
        this.columnsStyle =
          this.$store.state.tablePrime.columnsStyle?.book_table || {}
      },
      immediate: true,
      deep: true
    },
    selectedColumns: {
      handler: function(val) {
        this.activeColumns = cloneDeep(val)
      },
      immediate: true,
      deep: true
    },
    selectedObjects: {
      handler: function(val) {
        this.selectedObjectsHandler(val)
      },
      deep: true
    },
    tableData: {
      handler: function(val) {
        this.$store.commit('TABLE_PRIME_SET_TABLE_DATA', {
          tableId: this.tableId,
          data: val
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isIconCell(item) {
      return this.isIconsLayer && item.origin_title === 'resource_id'
    },
    getItemTitle(item) {
      return item.alias || item.title
    },
    handlePage(value) {
      const { page, rows } = value

      this.page = page
      this.$store.commit('TABLE_PRIME_SET_FIELD', {
        tableId: this.tableId,
        field: 'rows',
        value: rows
      })
      this.saveModuleConfig()
    },
    hasSortIcon(col) {
      if (!this.multisort) {
        return this.sortConfig.field === col && this.sortConfig.order !== 0
      } else {
        const config = this.multiSortConfig[col]

        if (!config) return false

        return config.field === col && config.order !== 0
      }
    },
    getSortIcon(col) {
      if (!this.multisort) {
        switch (this.sortConfig.order) {
          case 1:
            return 'sort-down'
          case -1:
            return 'sort-up'
          default:
            return ''
        }
      } else {
        const config = this.multiSortConfig[col]

        switch (config?.order) {
          case 1:
            return 'sort-down'
          case -1:
            return 'sort-up'
          default:
            return ''
        }
      }
    },
    customSort(col) {
      if (!this.multisort) {
        const initialField = this.sortConfig.field
        const initialOrder = this.sortConfig.order
        const config = {
          field: col,
          order: null
        }

        if (!initialField || initialField !== col) {
          config.field = col
          config.order = 1
        } else {
          switch (initialOrder) {
            case 0:
              config.order = 1
              break
            case 1:
              config.order = -1
              break
            case -1:
              config.order = 0
              break
          }
        }

        this.$store.commit('TABLE_PRIME_SET_FIELD', {
          tableId: this.tableId,
          field: 'sortConfig',
          value: config
        })
      } else {
        const initConfig = this.multiSortConfig?.[col]

        const config = {
          field: col,
          order: null
        }

        if (!initConfig) {
          config.field = col
          config.order = 1
        } else {
          switch (initConfig?.order) {
            case 0:
              config.order = 1
              break
            case 1:
              config.order = -1
              break
            case -1:
              config.order = 0
              break
          }
        }

        this.$store.commit('TABLE_PRIME_SET_FIELD', {
          tableId: this.tableId,
          field: `multiSortConfig.${col}`,
          value: config
        })
      }
      this.saveModuleConfig()
    },
    async saveModuleConfig() {
      if (!this.activeBookId || this.tableId !== 'book_table') return

      try {
        await this.$store.dispatch('SAVE_MODULE_USER_CONFIG', {
          module: 'book',
          id: this.activeBookId
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    selectedObjectsHandler(selections) {
      if (!this.tableId) return

      this.$store.commit('TABLE_PRIME_SET_SELECTED_OBJECT', {
        tableId: this.tableId,
        selections
      })
    },
    getValue(item, obj) {
      const key = item.reflection
        ? item.reflection.name + '.' + (item.reflection.default_show_attribute || 'id')
        : item.origin_title
      const value = obj[key] || obj[item.origin_title]

      if (Array.isArray(value)) {
        return value.map((x) => formatValue(item, x)).join(', ')
      } else {
        return formatValue(item, value)
      }
    },
    setSingleColumnWidth() {
      if (this.tableId === 'book_table') {
        const tableWidth =
          document.querySelector('.book-content')?.clientWidth || 0
        const allColumnsWidth =
          typeof tableWidth === 'number' ? tableWidth - 40 : null
        const columnsLength = this.columns?.length
        this.singleColumnWidth = allColumnsWidth / columnsLength
      }
    },
    getHeaderStyle(item) {
      if (this.tableId === 'book_table') {
        if (!item?.style && this.singleColumnWidth < 120) {
          return 'width: 120px;'
        } else {
          let style = 'max-width: 120px;'
          if (item?.style?.width) {
            style = `width: ${item.style.width}px;`
          }

          return style
        }
      }

      if (!item?.style) return 'max-width: 120px;'
      let style = 'max-width: 120px;'
      if (item?.style?.width) {
        style += `width: ${item.style.width}px;`
      }

      return style
    },
    onColumnResizeEnd({ element }) {
      const index = element.cellIndex
      const name = this.selectedColumns?.[index - 1]
      const width = element.offsetWidth
      const calcWidth = Number(width)
      if (!this.columnsStyle[name]) {
        this.columnsStyle[name] = {}
      }
      this.columnsStyle[name].width = calcWidth.toFixed()
      this.$store.commit('TABLE_PRIME_SET_FIELD', {
        tableId: this.tableId,
        field: 'columnsStyle',
        value: this.columnsStyle
      })
      this.$store.commit('TABLE_PRIME_SET_FIELD', {
        tableId: this.tableId,
        field: 'updateColumnsStyle',
        value: true
      })
    },
    onColumnReorder({ dragIndex, dropIndex }) {
      if (!this.tableId) return
      arrayMoveByIndex(this.selectedColumns, dragIndex - 1, dropIndex - 1)
      this.$store.commit('TABLE_PRIME_SET_FIELD', {
        tableId: this.tableId,
        field: 'columnsReorder',
        value: this.selectedColumns
      })
    },
    clickHandler(e) {
      if (!this.clickable) return
      const selectionColumn = e.originalEvent?.path?.find(e => {
        return e.className?.includes('p-selection-column')
      })
      const checkboxIcon = e.originalEvent?.target?.className?.includes('p-checkbox-icon')

      if (selectionColumn || checkboxIcon) {
        return
      }

      this.$emit('click-handler', e)
    }
  }
}
</script>

<style lang="scss">
.table-prime {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
  position: relative;
  width: 100%;

  .p-datatable {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    height: 0px;

    &-thead {
      height: 44px;

      th {
        &:first-child {
          padding: 0 !important;
        }
      }
    }

    &-scrollable {
      &-wrapper {
        max-height: 100%;
        height: auto;
      }

      &-header {
        background: none !important;
      }
    }

    &-header {
      background: none !important;
      border: none !important;
    }

    tr.p-highlight {
      background-color: var(--accent_selected) !important;
    }

    td {
      border-color: var(--table_border) !important;
      position: relative;
      padding: 0 !important;

      .table-prime__cell {
        position: relative;
        color: var(--text_primary) !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        font-family: var(--font-family) !important;
        min-height: 32px;
        display: flex;
        align-items: center;

        &-inner {
          padding: 4px 8px;
        }

        em.el-icon-arrow-down {
          display: none;
          position: absolute;
          top: 51%;
          right: 10px;
          transform: translateY(-50%);
        }

        &:hover {
          em.el-icon-arrow-down {
            display: block;
          }
        }

        &.center {
          justify-content: center;
        }
      }

      &:first-child {
        background-color: var(--table_header_bg);
      }
    }

    td,
    th {
      .el-checkbox__inner {
        width: 18px;
        height: 18px;
      }
    }

    .p-checkbox {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;

      &-box {
        border-color: var(--icons_low_contrast) !important;
        outline: none !important;
        background: none !important;
        box-shadow: none !important;

        &.p-highlight {
          background-color: var(--accent_primary) !important;
          border-color: var(--accent_primary) !important;
        }

        .p-checkbox-icon {
          font-size: 10px;
          font-weight: 900;
        }
      }
    }

    &.clickable {
      tbody {
        tr {
          cursor: pointer;

          &:hover {
            background-color: var(--accent_hover) !important;
          }
        }
      }
    }
  }

  .p-datatable-reorder-indicator-up,
  .p-datatable-reorder-indicator-down {
    color: var(--text_primary) !important;
  }

  &__header-sort {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .r-title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block !important;
      width: calc(100% - 0.5rem);
    }

    > * {
      flex-shrink: 0;
    }

    .r-icon {
      position: absolute;
      top: 50%;
      right: -0.5rem;
      display: flex;
      transform: translateY(-50%);
    }
  }
}
</style>
